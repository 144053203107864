import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const PenIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			className={classNames('pointer-events-none', className)}
		>
			<path
				fill={color}
				fillRule="evenodd"
				d="M17.426 3.012a2 2 0 00-2.851.023l-2.68 2.77-9.332 9.709A2 2 0 002 16.904V21a1 1 0 001 1h4.586A2 2 0 009 21.414l9.207-9.207L21 9.414a2 2 0 000-2.828l-3.574-3.574zm-1.414 1.414L19.586 8l-2.078 2.078-3.5-3.582 2.004-2.07zm-3.396 3.508l3.478 3.558L7.586 20H4v-3.096l8.617-8.97z"
				clipRule="evenodd"
			></path>
		</svg>
	);
};
