import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const UnreadIcon = ({ className, color, size = 16 }: IconProps) => {
	return (
		<svg
			className={classNames('pointer-events-none', className)}
			width={size}
			height={size}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24">
			<path
				d="M 3,2.25 V 22.860937 C 3,23.489062 3.5109375,24 4.1390625,24 c 0.234375,0 0.4640625,-0.07031 0.65625,-0.20625 L 12,18.75 19.204687,23.79375 C 19.396875,23.929687 19.626562,24 19.860937,24 20.489062,24 21,23.489063 21,22.860937 V 2.25 C 21,1.0078125 19.992187,0 18.75,0 H 5.25 C 4.0078125,0 3,1.0078125 3,2.25 Z"
				fill={color}
			/>
		</svg>
	);
};
