import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const MailIcon = ({ color, className, size = 11 }: IconProps) => {
	const width = size;
	const height = (11 / 10) * size;

	return (
		<svg
			className={classNames('pointer-events-none', className)}
			width={width}
			height={height}
			viewBox="0 0 11 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.00596788 1.23416C0.00596788 0.896651 0.279572 0.623047 0.617079 0.623047H10.3889C10.7264 0.623047 11 0.896651 11 1.23416V8.15452C11 8.82953 10.4528 9.37674 9.77778 9.37674H1.22222C0.547207 9.37674 0 8.82953 0 8.15452V1.43229C0 1.40319 0.00203412 1.37457 0.00596788 1.34655V1.23416ZM1.22222 2.59312V8.15452H9.77778V2.59333L6.79647 5.57464C6.08051 6.2906 4.9197 6.2906 4.20374 5.57464L1.22222 2.59312ZM2.18309 1.8255H8.81712L5.93223 4.7104C5.69357 4.94905 5.30664 4.94905 5.06798 4.7104L2.18309 1.8255Z"
				fill={color}
			/>
		</svg>
	);
};
