import { IconProps } from '@canify/ui-icon';
import { Link } from '@canify/ui-link';
import React, { CSSProperties, FC, ReactNode } from 'react';

export type ButtonProps = {
	href?: string;
	target?: React.HTMLAttributeAnchorTarget;
	className?: string;
	classNameIcon?: string;
	style?: CSSProperties;
	children?: ReactNode;
	onClick?: () => void;
	iconLeft?: FC<IconProps>;
	iconRight?: FC<IconProps>;
	iconOnly?: FC<IconProps>;
	iconProps?: {
		isFillColor: boolean;
	};
	buttonType?: 'button' | 'submit' | 'reset';
	type?: 'filled' | 'outlined' | 'text';
	size?: 'large' | 'medium' | 'small' | 'OVERRIDE';
	color?: 'action-red' | 'action-blue' | 'white' | 'dark-red';
	title?: string;
	disabled?: boolean;
	ariaLabel?: string;
	wordWrapMobile?: boolean;
	download?: string;
	textCenter?: boolean;
};

export const Button = ({
	className,
	style,
	classNameIcon,
	children,
	buttonType = 'button',
	onClick,
	href,
	target,
	iconLeft: IconLeftProp,
	iconRight: IconRightProp,
	iconOnly: IconOnlyProp,
	iconProps = { isFillColor: true },
	type = 'filled',
	size = 'medium',
	disabled = false,
	color = 'action-red',
	title,
	ariaLabel,
	wordWrapMobile,
	download,
	textCenter,
}: ButtonProps): JSX.Element => {
	const buttonClassesSize = getButtonClassesSize(size, type, wordWrapMobile);
	const buttonClassesColor = getButtonClassesColor(type, color);
	const iconClassesSize = getIconClassesSize(size);
	const iconRightMargin = getIconRightOffset(size);
	const iconRightWrapperMargin = getIconRightWrapperMargin(size, type);
	const iconLeftMargin = getIconLeftOffset(size);
	const iconLeftWrapperMargin = getIconLeftWrapperMargin(size, type);
	const iconClassesColor = getIconClassesColor(type, color, iconProps.isFillColor);

	const iconLeft = IconLeftProp && (
		<span className={iconLeftWrapperMargin}>
			<IconLeftProp
				className={`absolute top-1/2 -translate-y-1/2 ${iconLeftMargin} ${iconClassesSize} ${iconClassesColor} ${classNameIcon}`}
			/>
		</span>
	);

	const iconRight = IconRightProp && (
		<span className={iconRightWrapperMargin}>
			<IconRightProp
				className={`absolute top-1/2 -translate-y-1/2 ${iconRightMargin} ${iconClassesSize} ${iconClassesColor} ${classNameIcon}`}
			/>
		</span>
	);

	const iconOnly = IconOnlyProp && (
		<IconOnlyProp
			className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${iconClassesSize} ${iconClassesColor} ${classNameIcon}`}
		/>
	);

	const buttonClassesIconOnly = iconOnly && 'pl-0 pr-0';

	if (href) {
		return (
			<Link
				to={href}
				title={title}
				className={`relative inline-flex items-center group ${buttonClassesColor} ${buttonClassesSize} ${buttonClassesIconOnly} ${className}`}
				target={target}
				style={{ ...style }}
				onClick={onClick}
				aria-label={ariaLabel}
				download={download}
			>
				<span className={`relative w-full ${wordWrapMobile ? 'sm:whitespace-nowrap' : 'whitespace-nowrap'}`}>
					{iconLeft}
					{iconOnly}
					{!iconOnly && children}
					{iconRight}
				</span>
			</Link>
		);
	}

	return (
		<button
			type={buttonType}
			className={`relative transition-colors group ${
				wordWrapMobile ? 'sm:whitespace-nowrap' : 'whitespace-nowrap'
			}  ${buttonClassesColor} ${buttonClassesSize} ${buttonClassesIconOnly} ${className} `}
			style={{ ...style }}
			onClick={onClick}
			disabled={disabled}
			aria-label={ariaLabel}
		>
			<span className={`relative h-full inline-flex items-center ${!textCenter && 'w-full'}`}>
				{iconLeft}
				{iconOnly}
				{!iconOnly && children}
				{iconRight}
			</span>
		</button>
	);
};

function getButtonClassesColor(type: ButtonProps['type'], color: ButtonProps['color']) {
	if (type === 'filled') {
		if (color === 'action-red' || color === undefined) {
			return 'bg-action-red hover:bg-red-tint-100 text-white';
		}

		if (color === 'action-blue') {
			return 'bg-action-blue hover:bg-action-blue-shade text-white';
		}

		if (color === 'white') {
			return 'bg-white text text-opacity-100';
		}

		if (color === 'dark-red') {
			return 'bg-red-shade-75 hover:bg-red-shade-50 text-white';
		}
	}

	if (type === 'outlined') {
		if (color === 'action-red' || color === undefined) {
			return 'bg-opacity-10 text-action-red hover:text-action-red-shade border-2';
		}

		if (color === 'action-blue') {
			return 'bg-opacity-10 text-action-blue hover:text-action-blue-shade border-2';
		}

		if (color === 'white') {
			return 'bg-opacity-10 text-white border-2';
		}

		if (color === 'dark-red') {
			return 'bg-opacity-10 text-red-shade-75 hover:text-red-shade-50 border-2';
		}
	}

	if (type === 'text') {
		if (color === 'action-red' || color === undefined) {
			return 'bg-opacity-10 text-action-red hover:text-action-red-shade';
		}

		if (color === 'action-blue') {
			return 'bg-opacity-10 text-action-blue hover:text-action-blue-shade';
		}

		if (color === 'white') {
			return 'bg-opacity-10 text-white';
		}

		if (color === 'dark-red') {
			return 'bg-opacity-10 text-red-shade-75 hover:text-red-shade-50';
		}
	}
}

function getIconClassesColor(type: ButtonProps['type'], color: ButtonProps['color'], isFillColor: boolean) {
	const fillOrStrokeClass = isFillColor ? 'fill-current' : 'stroke-current';

	if (type === 'filled') {
		return `${fillOrStrokeClass} text-white`;
	}

	if (type === 'outlined' || type === 'text') {
		if (color === 'action-red' || color === undefined) {
			return `${fillOrStrokeClass} text-action-red group-hover:text-action-red-shade`;
		}

		if (color === 'action-blue') {
			return `${fillOrStrokeClass} text-action-blue group-hover:text-action-blue-shade`;
		}

		if (color === 'white') {
			return `${fillOrStrokeClass} text-white`;
		}

		if (color === 'dark-red') {
			return `${fillOrStrokeClass} text-red-shade-75 group-hover:text-red-shade-50`;
		}
	}
}

function getButtonClassesSize(
	size: ButtonProps['size'],
	type: ButtonProps['type'],
	wordWrapMobile: ButtonProps['wordWrapMobile'],
) {
	if (size === 'OVERRIDE') {
		return '';
	}

	if (size === 'large') {
		return `button-large ${
			type !== 'text' ? `px-10 ${wordWrapMobile ? 'h-auto sm:h-14 py-2 sm:py-0' : 'h-14'}` : ''
		}`;
	}

	if (size === 'medium') {
		return `button-medium ${type !== 'text' ? 'px-8' : ''} ${
			wordWrapMobile ? 'h-auto sm:h-11 py-1 sm:py-0' : 'h-11'
		}`;
	}

	return `button-small ${type !== 'text' ? 'px-8' : ''} ${
		wordWrapMobile ? 'h-auto sm:h-9 py-1 sm:py-0' : 'h-9'
	}`;
}

function getIconClassesSize(size: ButtonProps['size']) {
	if (size === 'large') {
		return 'icon-large';
	}

	if (size === 'medium') {
		return 'icon-medium';
	}

	return 'icon-small';
}

function getIconRightOffset(size: ButtonProps['size']) {
	if (size === 'large') return '-right-5';
	if (size === 'medium') return '-right-4';
	return '-right-4';
}

function getIconLeftOffset(size: ButtonProps['size']) {
	if (size === 'large') return '-left-5';
	if (size === 'medium') return '-left-4';
	return '-left-4';
}

function getIconRightWrapperMargin(size: ButtonProps['size'], type: ButtonProps['type']) {
	if (size === 'large' && type !== 'text') return 'mr-5';
	if (size === 'medium') return 'mr-4';
	return 'mr-2';
}

function getIconLeftWrapperMargin(size: ButtonProps['size'], type: ButtonProps['type']) {
	if (size === 'large' && type !== 'text') return 'ml-5';
	if (size === 'medium') return 'ml-4';
	return 'ml-2';
}
