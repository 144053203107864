import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const CalendarIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			className={classNames('pointer-events-none', className)}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 2C8 1.44772 7.55228 1 7 1C6.44772 1 6 1.44772 6 2V4H5C3.89543 4 3 4.89543 3 6V9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V9V6C21 4.89543 20.1046 4 19 4H18V2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2V4H8V2ZM7 6H17H19V9H5V6H7ZM5 11H19V19H5V11ZM7 12C6.44772 12 6 12.4477 6 13V14C6 14.5523 6.44772 15 7 15H11C11.5523 15 12 14.5523 12 14V13C12 12.4477 11.5523 12 11 12H7Z"
				fill={color}
			/>
		</svg>
	);
};
