
import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const ZoomResetIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 11.269076,13.806099 q 0,0.132339 -0.11843,0.243781 -0.118431,0.111443 -0.257762,0.111443 -0.334392,0 -1.0031784,0.007 -0.6687856,0.007 -1.0031787,0.007 -0.2577613,0 -0.2507949,-0.222885 l 0.00697,-0.07662 Q 8.740233,11.215128 8.740233,10.65095 V 8.5822309 q 0,-0.1950244 -0.090564,-0.1950244 -0.1393306,0 -0.4249577,0.083582 -0.2786611,0.083582 -0.4249576,0.083582 -0.2159623,0 -0.2159623,-0.257711 0,-0.2368152 -0.00697,-0.7104463 -0.00697,-0.4736306 -0.00697,-0.7174109 0,-0.1393034 0.1741627,-0.208955 Q 8.7680925,6.2349727 9.35328,5.5175618 9.4717108,5.3712933 9.555309,5.3712933 q 0.013933,0 0.083599,0.01393 0.2647273,0.055722 0.787217,0.1741292 0.27866,0.034826 0.815082,0.1253727 0.09753,0.027861 0.09753,0.1532335 0,0.041791 -0.01393,0.1462684 -0.07663,0.6129339 -0.07663,3.0019829 0,0.8009932 0.007,2.409945 0.01393,1.608952 0.01393,2.409944 z m 8.231877,-4.057528 c 0,2.151247 -0.698472,4.138456 -1.875092,5.75072 l 5.934665,5.938192 c 0.585966,0.585851 0.585966,1.537274 0,2.123126 -0.585966,0.585852 -1.537575,0.585852 -2.123541,0 L 15.50232,17.622417 C 13.889741,18.803494 11.902144,19.497142 9.7504763,19.497142 4.3642757,19.497142 0,15.133719 0,9.748571 0,4.3634229 4.3642757,0 9.7504763,0 15.136677,0 19.500953,4.3634229 19.500953,9.748571 Z m -9.7504767,6.749011 c 3.7267447,0 6.7503297,-3.022995 6.7503297,-6.749011 0,-3.7260163 -3.023585,-6.7490107 -6.7503297,-6.7490107 -3.7267445,0 -6.7503297,3.0229944 -6.7503297,6.7490107 0,3.726016 3.0235852,6.749011 6.7503297,6.749011 z"
				fill={color}
			/>
		</svg>
	);
};
