import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const TransferIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			className={classNames('pointer-events-none', className)}
			viewBox="0 0 512 512"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 385.256,66.104233 c 10.49795,-4.284439 22.48311,-1.885154 30.53154,5.912524 l 55.98906,54.840813 c 5.24898,5.14132 8.2234,12.08211 8.2234,19.36566 0,7.28354 -2.97442,14.22433 -8.2234,19.36565 l -55.98906,54.84082 c -8.04843,7.88336 -20.03359,10.19695 -30.53154,5.91252 -10.49795,-4.28444 -17.32161,-14.22433 -17.32161,-25.36387 v -27.24903 h -27.99454 c -8.83577,0 -17.14665,4.02737 -22.39562,10.96816 l -37.09276,48.41415 -34.99316,-45.67211 27.29467,-35.64653 c 15.8344,-20.73667 40.76703,-32.90448 67.18687,-32.90448 h 27.99454 V 91.468106 c 0,-11.05385 6.82366,-21.079436 17.32161,-25.363873 z m -209.78402,212.765187 34.99316,45.67212 -27.29467,35.64652 c -15.8344,20.73668 -40.76703,32.90449 -67.18687,32.90449 H 59.994532 C 44.510057,393.09255 32,380.83905 32,365.67215 32,350.50523 44.510057,338.25174 59.994532,338.25174 H 115.9836 c 8.83577,0 17.14665,-4.02737 22.39562,-10.96816 z M 415.70006,439.9643 c -8.04843,7.88337 -20.03359,10.19697 -30.53154,5.91252 -10.49795,-4.28443 -17.32162,-14.22433 -17.32162,-25.36387 v -27.4204 h -27.90705 c -26.41984,0 -51.35247,-12.16781 -67.18687,-32.90449 L 138.37922,184.69748 c -5.24897,-6.94079 -13.55985,-10.96816 -22.39562,-10.96816 H 59.994532 C 44.510057,173.72932 32,161.47582 32,146.30892 32,131.142 44.510057,118.88851 59.994532,118.88851 H 115.9836 c 26.41984,0 51.35247,12.16781 67.18687,32.90448 l 134.37376,175.49059 c 5.24897,6.94079 13.55985,10.96816 22.39562,10.96816 h 27.99454 v -27.4204 c 0,-11.05385 6.82366,-21.07944 17.32161,-25.36388 10.49795,-4.28444 22.48311,-1.88515 30.53154,5.91253 l 55.98906,54.8408 c 5.24898,5.14133 8.2234,12.08212 8.2234,19.36566 0,7.28355 -2.97442,14.22434 -8.2234,19.36567 l -55.98906,54.84081 z"
				stroke={color}
			/>
		</svg>
	);
};
