import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const DoctorIcon = ({ className = 'stroke-current', color, size = 24 }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
		>
			<path
				stroke={color}
				strokeWidth="2"
				d="M9.5 11.173c-6.359.942-7.364 5.489-7.485 7.576-.039.659.358 1.235.968 1.487C4.534 20.877 7.843 22 12 22c4.157 0 7.466-1.123 9.017-1.764.61-.252 1.007-.828.968-1.487-.12-2.087-1.127-6.634-7.485-7.576"
			></path>
			<path stroke={color} d="M18 12c-6 0-6 10-6 10M6 12c6 0 6 10 6 10M16.5 14.5v4m-2-2h4"></path>
			<ellipse cx="12" cy="7" stroke={color} strokeWidth="2" rx="4" ry="5"></ellipse>
		</svg>
	);
};
