import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const Circle40Icon = ({ className, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M 8.7075738,17.073633 V 15.004909 H 4.5000002 V 13.279804 L 8.9600283,6.7510525 h 1.6549797 v 6.5217405 h 1.276296 v 1.732116 h -1.276296 v 2.068724 z m 0,-3.80084 V 9.7594678 L 6.3443199,13.272793 Z M 16.161993,6.7510525 c -0.995793,-1e-7 -1.771858,0.3506318 -2.328192,1.0518934 -0.673212,0.8508639 -1.009817,2.2440421 -1.009817,4.1795241 0,1.972882 0.306218,3.345022 0.918653,4.11641 0.612436,0.766712 1.41889,1.150068 2.419356,1.150068 0.99579,0 1.771855,-0.350631 2.32819,-1.051892 C 19.163394,15.346193 19.5,13.94834 19.5,12.003507 19.5,10.054 19.165733,8.6584852 18.497196,7.8169713 17.936187,7.1063594 17.157783,6.7510526 16.161993,6.7510525 Z m 0,1.6339411 c 0.238428,0 0.448807,0.077139 0.631135,0.2314166 0.187003,0.1496024 0.341281,0.4511456 0.462834,0.9046283 0.12155,0.4488074 0.182327,1.2762995 0.182327,2.4824685 0,1.206171 -0.06778,2.061713 -0.203365,2.56662 -0.102852,0.392706 -0.247781,0.6662 -0.434784,0.820476 -0.187002,0.149604 -0.399719,0.224406 -0.638147,0.224406 -0.23843,0 -0.451147,-0.0748 -0.63815,-0.224406 -0.182327,-0.149602 -0.334268,-0.448807 -0.455819,-0.897614 -0.121554,-0.453484 -0.182329,-1.283311 -0.182329,-2.489482 0,-1.206169 0.06778,-2.0640493 0.203366,-2.5736326 0.102852,-0.3927065 0.24778,-0.6638619 0.434782,-0.8134642 0.187003,-0.1542776 0.39972,-0.2314166 0.63815,-0.2314166 z M 12,0 C 5.3835298,0 0,5.3835298 0,12 0,18.61647 5.3835298,24 12,24 18.61647,24 24,18.61647 24,12 24,5.3835298 18.61647,0 12,0 Z m 0,1.8457031 C 17.618736,1.845703 22.154297,6.3812633 22.154297,12 22.154297,17.618736 17.618736,22.154297 12,22.154297 6.3812633,22.154297 1.8457031,17.618736 1.8457031,12 1.8457031,6.3812633 6.3812633,1.845703 12,1.8457031 Z"
				fill="currentColor"
			/>
		</svg>
	);
};
