import { AddIcon } from './add';
import { CalendarIcon } from './calendar';
import { CheckmarkIcon } from './checkmark';
import { ChevronDownIcon } from './chevron_down';
import { Circle5Icon } from './circle_5';
import { Circle10Icon } from './circle_10';
import { Circle20Icon } from './circle_20';
import { Circle40Icon } from './circle_40';
import { CircleYCIcon } from './circle_yc';
import { CloseIcon } from './close';
import { CommentIcon } from './comment';
import { DoctorIcon } from './doctor';
import { EditIcon } from './edit';
import { EllipseIcon } from './ellipse';
import { TransferIcon } from './transfer';
import { IconProps as InternalIconProps } from './icon-props';
import { IndeterminateIcon } from './indeterminate';
import { InfoIcon } from './info';
import { LocationIcon } from './location';
import { LogoutIcon } from './logout';
import { MailIcon } from './mail';
import { MenuIcon } from './menu';
import { NextIcon } from './next';
import { NextPageIcon } from './next_page';
import { OkCircleIcon } from './ok_circle';
import { PenIcon } from './pen';
import { PencileIcon } from './pencile';
import { PhoneIcon } from './phone';
import { PlayIcon } from './play';
import { PlayBoxIcon } from './play_box';
import { PlayButtonIcon } from './play_button';
import { PlusIcon } from './plus';
import { PreviousPageIcon } from './previous_page';
import { RotateLeftIcon } from './rotate_left';
import { RotateRightIcon } from './rotate_right';
import { QuoteIcon } from './quote';
import { RemoveIcon } from './remove';
import { SearchIcon } from './search';
import { TickIcon } from './tick';
import { TrashIcon } from './trash';
import { UnreadIcon } from './unread';
import { UserIcon } from './user';
import { ZoomInIcon } from './zoom_in';
import { ZoomOutIcon } from './zoom_out';
import { ZoomResetIcon } from './zoom_reset';

const icons = {
	AddIcon,
	CalendarIcon,
	CheckmarkIcon,
	ChevronDownIcon,
	Circle5Icon,
	Circle10Icon,
	Circle20Icon,
	Circle40Icon,
	CircleYCIcon,
	CloseIcon,
	CommentIcon,
	DoctorIcon,
	EditIcon,
	EllipseIcon,
	IndeterminateIcon,
	InfoIcon,
	LocationIcon,
	LogoutIcon,
	MailIcon,
	MenuIcon,
	NextIcon,
	NextPageIcon,
	OkCircleIcon,
	PenIcon,
	PencileIcon,
	PhoneIcon,
	PlayBoxIcon,
	PlayButtonIcon,
	PlayIcon,
	PlusIcon,
	PreviousPageIcon,
	RotateLeftIcon,
	RotateRightIcon,
	QuoteIcon,
	RemoveIcon,
	SearchIcon,
	TickIcon,
	TransferIcon,
	TrashIcon,
	UnreadIcon,
	UserIcon,
	ZoomInIcon,
	ZoomOutIcon,
	ZoomResetIcon,
};

export type IconsKeys = keyof typeof icons;

export const Icon = (name: IconsKeys) => {
	const IconComponent = icons[name];

	return IconComponent || null;
};

export type IconProps = InternalIconProps;
