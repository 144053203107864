import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const PlayButtonIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			className={classNames('pointer-events-none', className)}
		>
			<path stroke={color} strokeLinejoin="round" strokeWidth="2" d="M2 2H22V22H2z"></path>
			<path className="fill-current" fill={color} d="M16 12l-6 4V8l6 4z"></path>
		</svg>
	);
};
