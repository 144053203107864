import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const TickIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			className={classNames('pointer-events-none', className)}
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.68492 17.2C9.44492 17.2 9.2249 17.12 9.0449 16.94L5.06492 12.96C4.70492 12.6 4.70492 12.04 5.06492 11.68C5.42492 11.32 5.9849 11.32 6.3449 11.68L9.70491 15.02L17.6849 7.06004C18.0449 6.70004 18.6049 6.70004 18.9649 7.06004C19.3249 7.42004 19.3249 7.98004 18.9649 8.34004L10.3449 16.94C10.1449 17.12 9.92492 17.2 9.68492 17.2Z"
				fill={color}
			/>
		</svg>
	);
};
