import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const RotateRightIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 22.319056,10.286318 h 0.439632 C 23.446582,10.286318 24,9.7131851 24,9.0007861 v -6.856172 c 0,-0.51957 -0.299984,-0.990932 -0.765476,-1.18911799 -0.465492,-0.198186 -0.998222,-0.09106 -1.355099,0.27853199 l -2.151609,2.228256 c -4.53079,-4.633273 -11.8286729,-4.617204 -16.3336019,0.05356 -4.525619,4.686836 -4.525619,12.2821899 0,16.9690259 4.525618,4.686838 11.8597059,4.686838 16.3853239,0 0.646517,-0.669548 0.646517,-1.756893 0,-2.426442 -0.646517,-0.669548 -1.696461,-0.669548 -2.342977,0 -3.232585,3.347741 -8.4719579,3.347741 -11.7045419,0 -3.232585,-3.34774 -3.232585,-8.7737579 0,-12.1214989 3.217068,-3.331671 8.4150639,-3.34774 11.6528199,-0.05356 l -2.125747,2.206831 c -0.356878,0.36959 -0.46032,0.921298 -0.268951,1.403372 0.191369,0.482075 0.646517,0.7927449 1.148214,0.7927449 z"
				fill={color}
			/>
		</svg>
	);
};
