import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const UserIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			className={classNames('pointer-events-none', className)}
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 20C6 20 6 16.5 7.5 14.5C9 12.5 15 12.5 16.5 14.5C18 16.5 18 20 18 20"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<circle cx="12" cy="7" r="3" stroke={color} strokeWidth="2" />
		</svg>
	);
};
