import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const EditIcon = ({ className, color, size = 16 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6173 2.00783C11.0906 1.48109 10.2344 1.48811 9.71636 2.02342L7.92958 3.86976L1.70857 10.3426C1.46792 10.5913 1.33337 10.9238 1.33337 11.2698V14C1.33337 14.3682 1.63185 14.6667 2.00004 14.6667H5.05723C5.41085 14.6667 5.74999 14.5262 6.00004 14.2762L12.1381 8.13811L14 6.27619C14.5207 5.75549 14.5207 4.91127 14 4.39057L11.6173 2.00783ZM10.6745 2.95064L13.0572 5.33338L11.6721 6.71849L9.33861 4.33106L10.6745 2.95064ZM8.41102 5.28957L10.7293 7.66136L5.05723 13.3334H2.66671V11.2698L8.41102 5.28957Z"
				fill={color}
			/>
		</svg>
	);
};
