import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const RotateLeftIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M 1.6809439,10.286318 H 1.2413119 C 0.55341788,10.286318 -1.2499999e-7,9.7131855 -1.2499999e-7,9.0007865 V 2.1446135 C -1.2499999e-7,1.6250445 0.29998388,1.1536825 0.76547588,0.95549649 1.2309679,0.75731049 1.7636979,0.86443649 2.1205749,1.2340285 l 2.151609,2.228256 c 4.53079,-4.633273 11.8286731,-4.617204 16.3336021,0.05356 4.525619,4.686836 4.525619,12.2821895 0,16.9690255 -4.525618,4.686837 -11.8597061,4.686837 -16.3853241,0 -0.646517,-0.669548 -0.646517,-1.756894 0,-2.426442 0.646517,-0.669548 1.696461,-0.669548 2.342977,0 3.232585,3.347741 8.4719581,3.347741 11.7045421,0 3.232585,-3.34774 3.232585,-8.7737575 0,-12.1214975 -3.217068,-3.331672 -8.4150641,-3.347741 -11.6528201,-0.05356 l 2.125747,2.20683 c 0.356878,0.369591 0.46032,0.921298 0.268951,1.403373 -0.191369,0.482075 -0.646517,0.7927445 -1.148214,0.7927445 z"
				fill={color}
			/>
		</svg>
	);
};
