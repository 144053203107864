import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const PreviousPageIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 5.6458775,10.789691 c -0.669419,0.669419 -0.669419,1.756555 0,2.425973 L 15.92815,23.497936 c 0.669418,0.669419 1.756554,0.669419 2.425973,0 0.669418,-0.669419 0.669418,-1.756555 0,-2.425973 L 9.2821605,12 18.348767,2.928037 c 0.669419,-0.669418 0.669419,-1.756554 0,-2.425973 -0.669418,-0.669419 -1.756554,-0.669419 -2.425974,0 L 5.6405225,10.784336 Z"
				fill={color}
			/>
		</svg>
	);
};
