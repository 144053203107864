import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const PhoneIcon = ({ color, className, size = 12 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames('pointer-events-none', className)}
		>
			<path
				d="M1 4.41667V1.77778C1 1.48631 1.2363 1.25 1.52778 1.25H4.16667C4.45815 1.25 4.69444 1.48631 4.69444 1.77778V3.88889C4.69444 4.18035 4.45815 4.41667 4.16667 4.41667H3.11111C3.11111 6.7485 5.00147 8.63889 7.33333 8.63889V7.58333C7.33333 7.29187 7.56963 7.05556 7.86111 7.05556H9.97222C10.2637 7.05556 10.5 7.29187 10.5 7.58333V10.2222C10.5 10.5137 10.2637 10.75 9.97222 10.75H7.33333C3.83553 10.75 1 7.91448 1 4.41667Z"
				stroke={color}
				strokeWidth="1.2"
			/>
		</svg>
	);
};
