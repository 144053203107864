import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const NextIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			className={classNames('pointer-events-none', className)}
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.2827 11.2895L10.7235 4.79289C10.3293 4.40237 9.68999 4.40237 9.29571 4.79289C8.90143 5.18342 8.90143 5.81658 9.29571 6.20711L15.1444 12L9.29571 17.7929C8.90143 18.1834 8.90143 18.8166 9.29571 19.2071C9.68999 19.5976 10.3293 19.5976 10.7235 19.2071L17.2827 12.7105C17.6777 12.3192 17.6777 11.6808 17.2827 11.2895Z"
				fill={color}
			/>
		</svg>
	);
};
