import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const PlayBoxIcon = ({ className, color = 'currentColor', size = 16 }: IconProps) => {
	return (
		<svg
			className={classNames('pointer-events-none', className)}
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1.5" y="1.5" width="13" height="13" stroke={color} strokeLinejoin="round" />
			<path d="M10.5 8L6 11V5L10.5 8Z" fill={color} />
		</svg>
	);
};
