import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const CircleYCIcon = ({ className, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 17.62896,13.181961 1.633649,0.554424 q -0.375682,1.462219 -1.252274,2.175051 -0.870901,0.70674 -2.21425,0.70674 -1.662109,0 -2.732235,-1.212424 -1.070126,-1.218516 -1.070126,-3.326549 0,-2.2298842 1.075819,-3.4605853 1.075817,-1.2367934 2.829,-1.2367934 1.531193,0 2.487474,0.9687199 0.569215,0.5727025 0.853822,1.6449966 L 17.572038,10.422022 Q 17.424042,9.7274673 16.951594,9.325357 16.484837,8.9232467 15.813163,8.9232467 q -0.927822,0 -1.508424,0.7128323 -0.574906,0.712831 -0.574906,2.309087 0,1.693737 0.569215,2.412662 0.569216,0.718925 1.479961,0.718925 0.671673,0 1.155507,-0.456944 0.483834,-0.456944 0.694444,-1.437848 z M 7.6976138,16.618176 V 12.730844 L 4.5366727,7.3818242 H 6.5792175 L 8.6099894,11.036042 10.599559,7.3818242 h 2.007224 L 9.4340708,12.743444 v 3.874732 z M 12,0 C 5.3835294,0 0,5.3835296 0,12 0,18.61647 5.3835294,24 12,24 18.61647,24 24,18.61647 24,12 24,5.3835296 18.61647,0 12,0 Z m 0,1.8457031 C 17.618736,1.8457031 22.154297,6.3812636 22.154297,12 22.154297,17.618736 17.618736,22.154297 12,22.154297 6.3812632,22.154297 1.8457031,17.618736 1.8457031,12 1.8457031,6.3812636 6.3812632,1.8457031 12,1.8457031 Z"
				fill="currentColor"
			/>
		</svg>
	);
};
