const { fontFamily } = require('tailwindcss/defaultTheme');

module.exports = {
	important: true,
	mode: 'jit',
	darkMode: 'media',
	theme: {
		colors: {
			transparent: 'transparent',
			inherit: 'inherit',
			current: 'currentColor',
			white: {
				DEFAULT: '#fff',
			},
			black: {
				DEFAULT: '#000',
			},
			'dark-blue': {
				DEFAULT: '#002039',
			},
			'middle-blue': {
				DEFAULT: '#00AACE',
			},
			'light-blue': {
				DEFAULT: '#CEE9EF',
			},
			'brand-blue': {
				DEFAULT: '#17335B',
			},
			'action-blue': {
				DEFAULT: '#17335B',
				shade: '#051C3E',
				tint: '#455C7E',
			},
			'blue-shade': {
				25: '#01060C',
				50: '#031329',
				75: '#051C3E',
			},
			'blue-tint': {
				25: '#DADFE5',
				50: '#8393A9',
				75: '#455C7E',
			},
			'brand-ochre-red': {
				DEFAULT: '#D45548',
			},
			'action-red': {
				DEFAULT: '#D45548',
				shade: '#772820',
				tint: '#B76860',
			},
			'red-shade': {
				25: '#180806',
				50: '#4F1B15',
				75: '#772820',
			},
			'red-tint': {
				25: '#E7CCCA',
				50: '#CF9B95',
				75: '#B76860',
				100: '#BD4A3E',
			},
			'brand-cream-yellow': {
				DEFAULT: '#FFE99F',
			},
			sand: {
				DEFAULT: '#EAE3D4',
			},
			'action-sand': {
				DEFAULT: '#FCFBF9',
				shade: '#F5F1EA',
				tint: '#FFFFFF',
			},
			'sand-shade': {
				25: '#232220',
				50: '#75716A',
				75: '#AFAA9F',
				100: '#D7D1C6',
			},
			'sand-tint': {
				25: '#FCFBF9',
				50: '#F5F1EA',
				75: '#EFEADF',
			},
			green: {
				DEFAULT: '#D3E7C0',
				darker: '#B2CF95',
				verydark: '#444F39',
			},
			neutral: {
				10: '#F9F8F9',
				20: '#EAEAEA',
				30: '#CCCCCC',
				40: '#8B8C8C',
				50: '#4F4F4F',
				60: '#2D2F30',
			},
			newpatientbooking: {
				DEFAULT: '#EEBBB6',
				shade: '#D4A5A1',
			},
			followup: {
				DEFAULT: '#FFF2C5',
				shade: '#E6DAB1',
			},
			prescription: {
				DEFAULT: '#ADB9DD',
				shade: '#99A4C4',
			},
			yearlycheck: {
				DEFAULT: '#B4B597',
				shade: '#9B9C81',
			},
			inbetweenprescription: {
				DEFAULT: '#ADB9DD',
				shade: '#99A4C4',
			},
			preliminary: {
				DEFAULT: '#EFEADF',
				shade: '#D6D1C7',
			},
		},
		fontFamily: {
			sans: ['Gotham', ...fontFamily.sans],
			display: ['"Gotham Ultra"', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'],
			body: ['"Gotham"', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'],
		},
		fontSize: {
			'mobile-huge': '55px',
			'mobile-small': '25px',
			'mobile-h1': '40px',
			'mobile-h2': '40px',
			'mobile-h3': '24px',
			'mobile-h4': '24px',
			'mobile-body-large': '18px',
			'tablet-huge': '80px',
			'tablet-large': '66px',
			'tablet-h1': '48px',
			'tablet-h2': '45px',
			'tablet-h3': '28px',
			'tablet-h4': '26px',
			'tablet-body-large': '21px',
			'desktop-huge': '100px',
			'desktop-large': '88px',
			'desktop-h1': '60px',
			'desktop-h2': '48px',
			'desktop-h3': '28px',
			'desktop-h4': '28px',
			h5: '20px',
			'desktop-body-large': '24px',
			'body-medium': '16px',
			'body-small': '14px',
			'body-smaller': '12px',
			'body-xs': '10px',
			'button-small': '12px',
			'button-medium': '14px',
			'button-large': '16px',
			'label-small': '12px',
		},
		lineHeight: {
			'mobile-huge': '50px',
			'mobile-small': '30px',
			'mobile-h1': '40px',
			'mobile-h2': '48px',
			'mobile-h3': '28.8px',
			'mobile-h4': '36px',
			'mobile-body-large': '27px',
			'tablet-huge': '72px',
			'tablet-h1': '48px',
			'tablet-h2': '54px',
			'tablet-h3': '33.6px',
			'tablet-h4': '39px',
			'tablet-body-large': '31.5px',
			'desktop-huge': '90px',
			'desktop-h1': '60px',
			'desktop-h2': '57.6px',
			'desktop-h3': '33.6px',
			'desktop-h4': '42px',
			h5: '24px',
			'desktop-body-large': '36px',
			'body-large': '36px',
			'body-medium': '24px',
			'body-small': '21px',
			'body-smaller': '16px',
			'body-xs': '12px',
			'button-small': '16px',
			'button-medium': '16px',
			'button-large': '26px',
			'label-small': '17px',
		},
		fontWeight: {
			normal: 400,
			medium: 500,
			bold: 700,
			black: 900,
		},
		screens: {
			xs: '440px',
			sm: '520px', // mobile
			md: '768px', // tablet
			'md-lg': '974px',
			'lg-2': '1200px', // desktop
			lg: '1440px', // desktop
			header: '1270px',
		},
		gridTemplateRows: {
			'body-with-bar': '1fr auto',
			1: 'repeat(1, minmax(0, 1fr))',
			2: 'repeat(2, minmax(0, 1fr))',
			3: 'repeat(3, minmax(0, 1fr))',
			4: 'repeat(4, minmax(0, 1fr))',
			5: 'repeat(5, minmax(0, 1fr))',
			6: 'repeat(6, minmax(0, 1fr))',
			none: 'none',
		},
		headerHeight: '112px',
		extend: {
			fontFamily: {
				display: ['"Gotham Ultra"', ...fontFamily.sans],
			},
			maxWidth: {
				xxs: '266px',
			},
			boxShadow: {
				tooltip: '0 0 30px #3333333e',
			},
			margin: {
				calendar: '10px',
			},
		},
	},
};
