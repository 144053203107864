import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const PreviousIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.71732 11.2895L13.2765 4.79289C13.6707 4.40237 14.31 4.40237 14.7043 4.79289C15.0986 5.18342 15.0986 5.81658 14.7043 6.20711L8.85565 12L14.7043 17.7929C15.0986 18.1834 15.0986 18.8166 14.7043 19.2071C14.31 19.5976 13.6707 19.5976 13.2765 19.2071L6.71732 12.7105C6.32227 12.3192 6.32227 11.6808 6.71732 11.2895Z"
				fill={color}
			/>
		</svg>
	);
};
