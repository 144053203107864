import NextLink from 'next/link';
import React from 'react';
import { Url, UrlObject } from 'url';

type AnchorProps = JSX.IntrinsicElements['a'];
export interface LinkProps extends Exclude<AnchorProps, 'href'> {
	children: React.ReactNode;
	locale?: string;
	to: string | UrlObject;
	as?: string | Url;
}

export const Link = ({ children, to, as, locale, target, rel, ...props }: LinkProps) => {
	const isExternal = typeof to === 'string' && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

	if (isExternal) {
		return (
			<a {...props} href={to} target={target || '_blank'} rel={rel || 'noopener noreferrer'}>
				{children}
			</a>
		);
	}

	return (
		<NextLink locale={locale} href={to} as={as}>
			<a target={target} rel={rel} {...props}>
				{children}
			</a>
		</NextLink>
	);
};
