import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const MenuIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7ZM8 12C8 11.4477 8.44772 11 9 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H9C8.44772 13 8 12.5523 8 12ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H19C19.5523 18 20 17.5523 20 17C20 16.4477 19.5523 16 19 16H7Z"
				fill={color}
			/>
		</svg>
	);
};
