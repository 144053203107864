import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const ZoomInIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 19.500954,9.7485714 c 0,2.1512476 -0.698474,4.1384566 -1.875094,5.7507206 l 5.934666,5.938194 c 0.585965,0.585851 0.585965,1.537274 0,2.123126 -0.585966,0.585851 -1.537575,0.585851 -2.123541,0 L 15.50232,17.622418 C 13.889741,18.803495 11.902144,19.497145 9.7504759,19.497145 4.3642761,19.497145 -2.5000005e-8,15.133721 -2.5000005e-8,9.7485714 -2.5000005e-8,4.3634234 4.3642761,1.7499996e-8 9.7504759,1.7499996e-8 15.136677,1.7499996e-8 19.500954,4.3634234 19.500954,9.7485714 Z M 9.7504759,16.497583 c 3.7267451,0 6.7503291,-3.022994 6.7503291,-6.7490116 0,-3.7260159 -3.023584,-6.7490113 -6.7503291,-6.7490113 -3.7267434,0 -6.7503301,3.0229954 -6.7503301,6.7490113 0,3.7260176 3.0235867,6.7490116 6.7503301,6.7490116 z m 1.55e-5,-11.9982493 c -0.6234667,0 -1.1250554,0.5014903 -1.1250554,1.1248354 V 8.6237307 H 5.6252901 c -0.6234682,0 -1.125055,0.5014888 -1.125055,1.1248354 0,0.6233449 0.5015868,1.1248349 1.125055,1.1248349 H 8.625436 v 2.99956 c 0,0.623345 0.5015887,1.124836 1.1250554,1.124836 0.6234656,0 1.1250556,-0.501491 1.1250556,-1.124836 v -2.99956 h 3.000146 c 0.623468,0 1.125055,-0.50149 1.125055,-1.1248349 0,-0.6233466 -0.501587,-1.1248354 -1.125055,-1.1248354 H 10.875547 V 5.6241691 c 0,-0.6233451 -0.50159,-1.1248354 -1.1250556,-1.1248354 z"
				fill={color}
			/>
		</svg>
	);
};
