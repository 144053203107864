import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const Circle5Icon = ({ className, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M 12 0 C 5.3835362 0 0 5.3835362 0 12 C 0 18.616463 5.3835362 24 12 24 C 18.616463 24 24 18.616463 24 12 C 24 5.3835362 18.616463 3.5527101e-15 12 0 z M 12 1.8457031 C 17.61873 1.845703 22.154297 6.3812687 22.154297 12 C 22.154297 17.61873 17.61873 22.154297 12 22.154297 C 6.3812692 22.154297 1.8457031 17.61873 1.8457031 12 C 1.8457031 6.3812687 6.3812692 1.845703 12 1.8457031 z M 9.7519531 6.75 L 8.7246094 12.197266 L 10.351562 12.431641 C 10.808393 11.922465 11.324593 11.667969 11.900391 11.667969 C 12.36198 11.667969 12.735965 11.830709 13.021484 12.154297 C 13.311762 12.473127 13.457031 12.953284 13.457031 13.595703 C 13.457031 14.280949 13.311762 14.794096 13.021484 15.136719 C 12.731207 15.474583 12.375793 15.644531 11.957031 15.644531 C 11.590615 15.644531 11.267089 15.513679 10.986328 15.251953 C 10.70557 14.985468 10.537575 14.6259 10.480469 14.173828 L 8.4804688 14.380859 C 8.5994351 15.256452 8.9631579 15.953963 9.5722656 16.472656 C 10.181373 16.99135 10.96954 17.25 11.935547 17.25 C 13.144245 17.25 14.089044 16.788413 14.769531 15.865234 C 15.26919 15.189505 15.519531 14.421863 15.519531 13.560547 C 15.519531 12.527919 15.210422 11.686239 14.591797 11.039062 C 13.973171 10.391885 13.217993 10.068359 12.328125 10.068359 C 11.861777 10.068359 11.404344 10.18064 10.957031 10.404297 L 11.271484 8.6269531 L 15.054688 8.6269531 L 15.054688 6.75 L 9.7519531 6.75 z "
				fill="currentColor"
			/>
		</svg>
	);
};
