import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const NextPageIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 18.354123,10.789691 c 0.66942,0.669419 0.66942,1.756555 0,2.425973 L 8.0718496,23.497936 c -0.669418,0.669419 -1.756554,0.669419 -2.425973,0 -0.669419,-0.669419 -0.669419,-1.756555 0,-2.425973 L 14.717843,12 5.6512316,2.928037 c -0.669418,-0.669418 -0.669418,-1.756554 0,-2.425973 0.669419,-0.669419 1.756555,-0.669419 2.425974,0 L 18.359473,10.784336 Z"
				fill={color}
			/>
		</svg>
	);
};
