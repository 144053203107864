import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const HistoryIcon = ({ className, color, size = 24, ...props }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		className={classNames('pointer-events-none', className)}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path fillRule="evenodd" clipRule="evenodd" d="M11 5h2v7h4v2h-6V5ZM1.5 3.5h2v3h3v2h-5v-5Z" fill={color} />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.077 7.727A9 9 0 1 1 3 12H1c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1a10.998 10.998 0 0 0-9.656 5.727l1.733 1Z"
			fill="#000"
		/>
	</svg>
);
