import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const LocationIcon = ({ color, className, size = 24 }: IconProps) => {
	const height = (25 / 24) * size;
	const width = size;

	return (
		<svg
			width={width}
			height={height}
			className={classNames('pointer-events-none', className)}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 10C21 14.9706 12 23 12 23C12 23 3 14.9706 3 10C3 5.02944 7.02944 1 12 1C16.9706 1 21 5.02944 21 10Z"
				stroke={color}
				strokeWidth="2"
			/>
			<circle cx="12" cy="9" r="4" stroke={color} strokeWidth="2" />
		</svg>
	);
};
