import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const ZoomOutIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m 19.500964,9.7485725 c 0,2.1512465 -0.698495,4.1384545 -1.8751,5.7507175 l 5.934653,5.938193 c 0.585978,0.585853 0.585978,1.537278 0,2.123127 -0.585941,0.585853 -1.53755,0.585853 -2.123528,0 L 15.502337,17.622417 C 13.889726,18.803493 11.902148,19.497146 9.7504624,19.497146 4.3642574,19.497146 1.35e-7,15.133719 1.35e-7,9.7485725 1.35e-7,4.3634228 4.3642574,-1.1125001e-7 9.7504624,-1.1125001e-7 15.136667,-1.1125001e-7 19.500964,4.3634228 19.500964,9.7485725 Z M 9.7504624,16.49758 c 3.7267656,0 6.7503496,-3.022991 6.7503496,-6.7490075 0,-3.7260169 -3.023584,-6.7490111 -6.7503496,-6.7490111 -3.7267278,0 -6.7503121,3.0229942 -6.7503121,6.7490111 0,3.7260165 3.0235843,6.7490075 6.7503121,6.7490075 z M 6.3753441,8.6237285 c -0.6234709,0 -1.1250898,0.5014903 -1.1250898,1.1248365 0,0.623346 0.5016189,1.124836 1.1250898,1.124836 h 6.7503119 c 0.623471,0 1.125052,-0.50149 1.125052,-1.124836 0,-0.6233462 -0.501581,-1.1248365 -1.125052,-1.1248365 z"
				fill={color}
			/>
		</svg>
	);
};
