import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const Circle10Icon = ({ className, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M 9.4508863,17.073683 H 7.4803202 V 9.6472419 Q 6.4003656,10.657069 4.9347125,11.140945 V 9.3527088 Q 5.7061086,9.1002518 6.6107464,8.3989826 7.5153832,7.6907006 7.8519924,6.7509999 H 9.4508863 Z M 15.727247,6.7509999 c -0.995802,-1e-7 -1.771875,0.3506352 -2.328214,1.0519038 -0.673219,0.8508726 -1.009828,2.2440633 -1.009828,4.1795643 0,1.972902 0.306222,3.345055 0.918661,4.11645 0.612444,0.766721 1.418904,1.150082 2.419381,1.150082 0.995799,0 1.771872,-0.350636 2.328213,-1.051904 0.673217,-0.850872 1.009827,-2.248738 1.009827,-4.19359 0,-1.949526 -0.334271,-3.3450546 -1.002814,-4.1865769 C 17.501457,7.1063103 16.723046,6.7509999 15.727247,6.7509999 Z m 0,1.6339573 c 0.238429,0 0.448811,0.07714 0.631141,0.2314189 0.187005,0.149604 0.341284,0.4511503 0.462839,0.9046371 0.121552,0.448812 0.18233,1.2763118 0.18233,2.4824928 0,1.206182 -0.06779,2.061732 -0.20337,2.566645 -0.102852,0.392711 -0.247782,0.666206 -0.434787,0.820486 -0.187005,0.149603 -0.399723,0.224406 -0.638153,0.224406 -0.238432,0 -0.451151,-0.0748 -0.638156,-0.224406 -0.182329,-0.149605 -0.334272,-0.448813 -0.455824,-0.897625 -0.121554,-0.453487 -0.18233,-1.283324 -0.18233,-2.489506 0,-1.206181 0.06779,-2.0640693 0.203367,-2.5736576 C 14.757156,9.037138 14.902085,8.76598 15.08909,8.6163761 15.276095,8.4620969 15.488814,8.3849572 15.727247,8.3849572 Z M 12,0 C 5.3835296,0 0,5.3835296 0,12 0,18.61647 5.3835296,24 12,24 18.61647,24 24,18.61647 24,12 24,5.3835296 18.61647,0 12,0 Z m 0,1.8457031 C 17.618736,1.845703 22.154297,6.3812631 22.154297,12 22.154297,17.618736 17.618736,22.154297 12,22.154297 6.3812636,22.154297 1.8457031,17.618736 1.8457031,12 1.8457031,6.3812631 6.3812636,1.845703 12,1.8457031 Z"
				fill="currentColor"
			/>
		</svg>
	);
};
