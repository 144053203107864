import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const LogoutIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M10 12L22 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M22 12L17 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M22 12L17 17" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10 3H2V21H10" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};
