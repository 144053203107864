import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const PlayIcon = ({ className, color, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.1036 10.7803C19.4089 11.5553 19.4089 13.4447 18.1036 14.2197L8.02108 20.2062C6.6879 20.9978 5 20.037 5 18.4865L5 6.51347C5 4.963 6.68789 4.00219 8.02108 4.79376L18.1036 10.7803Z"
				fill={color}
			/>
		</svg>
	);
};
