import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const InfoIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			className={classNames('pointer-events-none', className)}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 11C12.5523 11 13 11.4477 13 12V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V12C11 11.4477 11.4477 11 12 11Z"
				fill={color}
			/>
			<path
				d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V8C11 8.55228 11.4477 9 12 9C12.5523 9 13 8.55228 13 8V7Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
				fill={color}
			/>
		</svg>
	);
};
