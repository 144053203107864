import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const RemoveIcon = ({ color, className, size = 24 }: IconProps) => {
	return (
		<svg
			className={classNames('pointer-events-none', className)}
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.63575 5.63604C2.12103 9.15076 2.12103 14.8492 5.63575 18.364C9.15047 21.8787 14.849 21.8787 18.3637 18.364C21.8784 14.8492 21.8784 9.15076 18.3637 5.63604C14.849 2.12132 9.15047 2.12132 5.63575 5.63604ZM4.22153 19.7782C-0.0742334 15.4824 -0.0742334 8.51759 4.22153 4.22183C8.5173 -0.0739417 15.4821 -0.0739417 19.7779 4.22183C24.0736 8.51759 24.0736 15.4824 19.7779 19.7782C15.4821 24.0739 8.5173 24.0739 4.22153 19.7782ZM8.46417 15.5355C8.07365 15.145 8.07365 14.5118 8.46417 14.1213L10.5855 12L8.46417 9.87868C8.07365 9.48816 8.07365 8.85499 8.46417 8.46447C8.8547 8.07394 9.48786 8.07394 9.87839 8.46447L11.9997 10.5858L14.121 8.46447C14.5116 8.07394 15.1447 8.07394 15.5352 8.46447C15.9258 8.85499 15.9258 9.48816 15.5352 9.87868L13.4139 12L15.5352 14.1213C15.9258 14.5118 15.9258 15.145 15.5352 15.5355C15.1447 15.9261 14.5116 15.9261 14.121 15.5355L11.9997 13.4142L9.87839 15.5355C9.48786 15.9261 8.8547 15.9261 8.46417 15.5355Z"
				fill={color}
			/>
		</svg>
	);
};
