import classNames from 'classnames';

import { IconProps } from '@canify/ui-icon';

export const EllipseIcon = ({ className, color, size = 12 }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 12 12"
			className={classNames('pointer-events-none', className)}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="6" cy="6" r="6" fill={color} />
		</svg>
	);
};
